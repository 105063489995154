export const apiBaseUrl = 'https://apieclerk.geegz.fr'
//export const apiBaseUrl = 'http://192.168.1.80:8000'

export const vueBaseUrl = 'https://eclerk.geegz.fr'

export const stripePublishableKey = 'pk_live_bQl6Cg0KijSGp27rbVxOKDEs'

// Stripe Attribued
export const stripeSoloPrice = 'price_1HZai5FuYGx9Q9U8bZxHC5x2'
export const stripeSmallPrice = 'price_1HZaiYFuYGx9Q9U8JoRdwpiO'
export const stripeMediumPrice = 'price_1HZaigFuYGx9Q9U8dTgDtBxD'

export const stripeSoloProduct = 'prod_I9uXHKMUj9w9J2'
export const stripeSmallProduct = 'prod_I9uXaogCwl3tXV'
export const stripeMediumProduct = 'prod_I9uX6pTb8ixBVF'

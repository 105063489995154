import {
  cibFacebook,
  cibLinkedin,
  cil3d,
  cilAddressBook,
  cilAlignCenter,
  cilArrowCircleBottom,
  cilArrowCircleRight,
  cilArrowCircleTop,
  cilArrowLeft,
  cilArrowRight,
  cilBan,
  cilBasket,
  cilBell,
  cilBuilding,
  cilCalculator,
  cilCalendar,
  cilCaretLeft,
  cilCaretRight,
  cilCart,
  cilCenterFocus,
  cilCircle,
  cilClipboard,
  cilChartPie,
  cilCheck,
  cilChevronBottom,
  cilChevronTop,
  cilCheckCircle,
  cilClock,
  cilClone,
  cilCloudDownload,
  cilCommentSquare,
  cilContact,
  cilCopy,
  cilCursor,
  cilDescription,
  cilDrop,
  cilDollar,
  cilEnvelopeClosed,
  cilEnvelopeOpen,
  cilEuro,
  cilFrown,
  cilGlobeAlt,
  cilGrid,
  cilHappy,
  cilHeart,
  cilFile,
  cilFolder,
  cilInfo,
  cilJustifyCenter,
  cilLaptop,
  cilLayers,
  cilLibraryAdd,
  cilLightbulb,
  cilList,
  cilLocationPin,
  cilLockLocked,
  cilLoop,
  cilMagnifyingGlass,
  cilMoon,
  cilOptions,
  cilPencil,
  cilPeople,
  cilPlus,
  cilPuzzle,
  cilSave,
  cilSend,
  cilSettings,
  cilShareAlt,
  cilShieldAlt,
  cilSpeech,
  cilSpeedometer,
  cilStar,
  cilTask,
  cilTrash,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilWallet,
  cilWarning,
  cilXCircle

} from '@coreui/icons'
import { logo } from './logo'

export const iconsSet = Object.assign(
  {},
  { logo },
  {
    cibFacebook,
    cibLinkedin,
    cil3d,
    cilAddressBook,
    cilAlignCenter,
    cilArrowCircleBottom,
    cilArrowCircleRight,
    cilArrowCircleTop,
    cilArrowLeft,
    cilArrowRight,
    cilBan,
    cilBasket,
    cilBell,
    cilBuilding,
    cilCalculator,
    cilCalendar,
    cilCaretLeft,
    cilCaretRight,
    cilCart,
    cilCenterFocus,
    cilCircle,
    cilCloudDownload,
    cilClock,
    cilClipboard,
    cilChartPie,
    cilCheck,
    cilChevronBottom,
    cilChevronTop,
    cilCheckCircle,
    cilClone,
    cilCommentSquare,
    cilContact,
    cilCopy,
    cilCursor,
    cilDrop,
    cilDollar,
    cilDescription,
    cilEnvelopeClosed,
    cilEnvelopeOpen,
    cilEuro,
    cilFrown,
    cilGlobeAlt,
    cilGrid,
    cilHappy,
    cilHeart,
    cilFile,
    cilFolder,
    cilInfo,
    cilJustifyCenter,
    cilLaptop,
    cilLayers,
    cilLibraryAdd,
    cilLightbulb,
    cilList,
    cilLocationPin,
    cilLockLocked,
    cilLoop,
    cilMagnifyingGlass,
    cilMoon,
    cilOptions,
    cilPencil,
    cilPeople,
    cilPlus,
    cilPuzzle,
    cilSave,
    cilSend,
    cilSettings,
    cilShareAlt,
    cilShieldAlt,
    cilSpeech,
    cilSpeedometer,
    cilStar,
    cilTask,
    cilTrash,
    cilUser,
    cilUserFemale,
    cilUserFollow,
    cilWallet,
    cilWarning,
    cilXCircle
  },
)

import { AUTH_REQUEST, AUTH_ERROR, AUTH_SUCCESS, AUTH_LOGOUT, AUTH_INSPECT, AUTH_REFRESH } from '../actions/auth'
import { USER_SUCCESS } from '../actions/user'
//import { USER_REQUEST, USER_LOGOUT } from '../actions/user'
import { USER_LOGOUT } from '../actions/user'
import { AuthAPIService }  from '@/api/APIAuth'
import jwt_decode from 'jwt-decode'

const apiAuth = new AuthAPIService()

const state = {
  token: localStorage.getItem('avocanet-user-token') || '',
  status: '',
  hasLoadedOnce: false,
  isRefreshing: false
}

const getters = {
  isAuthenticated: state => !!state.token,
  authStatus: state => state.status,
  TOKEN: state => {return state.token}
}

const actions = {
  [AUTH_REQUEST]: ({commit}, user) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST)
      apiAuth.getToken(user)
        .then((response)=>{
          commit(AUTH_SUCCESS, JSON.stringify(response.data))
          commit(USER_SUCCESS, response.data)
          //dispatch(USER_REQUEST, JSON.stringify(response.data))
          resolve(response)
        })
        .catch((error)=>{
           commit(AUTH_ERROR, error)
           reject(error)
        })
    })
  },
  [AUTH_LOGOUT]: ({commit, dispatch}) => {
    return new Promise((resolve) => {
      commit(AUTH_LOGOUT)
      dispatch(USER_LOGOUT)
      resolve()
    })
  },
  [AUTH_INSPECT]: ({commit}) => {
    return new Promise((resolve, reject) => {
      const token = JSON.parse(state.token).access
      if( token ){
        const decoded = jwt_decode(token)
        const exp = decoded.exp
        if( exp - (Date.now()/1000) < 1800 ){
          apiAuth.refreshToken(JSON.parse(state.token).refresh)
            .then((response)=>{
              commit(AUTH_REFRESH, response.data)
            })
            .catch((error) => {
              reject(error)
            })
        }
      }
    })
  }
}

const mutations = {
  [AUTH_REQUEST]: (state) => {
    state.status = 'loading'
  },
  [AUTH_SUCCESS]: (state, token) => {
    state.status = 'success'
    state.token = token
    localStorage.setItem('avocanet-user-token', token)
    state.hasLoadedOnce = true
  },
  [AUTH_REFRESH]: (state, accessTokenJson) => {
    const accessToken = accessTokenJson.access
    var token = JSON.parse(state.token)
    token.access = accessToken
    state.token = JSON.stringify(token)
    localStorage.setItem('avocanet-user-token', JSON.stringify(token))
  },
  [AUTH_ERROR]: (state) => {
    state.status = 'error'
    state.token = ''
    localStorage.removeItem('avocanet-user-token')
    state.hasLoadedOnce = true
  },
  [AUTH_LOGOUT]: (state) => {
    state.token = ''
    localStorage.removeItem('avocanet-user-token')
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
}

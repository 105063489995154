<template>
  <div class="globalErrorModalComponent">
    <!-- ERROR MODAL -->
    <CModal
      :show.sync="isGlobalErrorModal"
      :no-close-on-backdrop="true"
      title="Erreur de communication avec le serveur"
      size="lg"
      color="dark"
    >
      Une erreur  de communication avec le serveur s'est produite,
      veuillez vérifier les informations renseignées, réactualiser la page puis réessayer.
      <template #footer>
        <CButton @click="$store.commit('closeGlobalErrorModal')" color="dark">Fermer</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
export default {
  name: 'globalErrorModalComponent',
  computed : {
    isGlobalErrorModal: {
      get () {
        return this.$store.state.isGlobalErrorModal
      },
      set () {
        this.$store.commit('closeGlobalErrorModal')
      }
    },
  }
}
</script>

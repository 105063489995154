import axios from 'axios'
import { apiBaseUrl } from '@/variables/localVariables'

export class AuthAPIService {

  register (company_name, siret, forme_sociale, precisions_reglementaires, capital, address, phone_country_code, phone_number,
    email, has_accepted_cgu_cgv_donnes, collaborateur_username, collaborateur_password, collaborateur_confirm_password, collaborateur_first_name,
    collaborateur_last_name, collaborateur_sex, collaborateur_birthdate,
    collaborateur_phone_country_code, collaborateur_phone_number) {
    const url = apiBaseUrl + '/register'
    var headers = {
      'Content-Type': 'application/json'
    }

    if (capital == '' || capital == 0 || capital == null) {
      capital = null
    }
    var data = {
      company: {
        company_name: company_name,
        siret: siret,
        forme_sociale: forme_sociale,
        precisions_reglementaires: precisions_reglementaires,
        capital: capital,
        address: address,
        phone_country_code: phone_country_code,
        phone_number: phone_number,
        email: email,
        has_accepted_cgu_cgv_donnes: has_accepted_cgu_cgv_donnes
      },
      collaborateur_username: collaborateur_username,
      collaborateur_password: collaborateur_password,
      collaborateur_confirm_password: collaborateur_confirm_password,
      collaborateur_first_name: collaborateur_first_name,
      collaborateur_last_name: collaborateur_last_name,
      collaborateur_sex: collaborateur_sex,
      collaborateur_birthdate: collaborateur_birthdate,
      collaborateur_phone_country_code: collaborateur_phone_country_code,
      collaborateur_phone_number: collaborateur_phone_number,
    }
    return axios.post(
      url,
      data,
      headers
    )
  }

  checkIfDuplicateUsername (username) {
    const url = apiBaseUrl + '/get-nb-users-by-username'
    var headers = {
      'Content-Type': 'application/json'
    }
    var params = {
      username: username
    }
    return axios.get(
      url,
      {'headers': headers, params: params}
    )
  }


  getToken (user) {
    const url = apiBaseUrl + `/auth/token`
    return axios.post(url, user)
  }

  refreshToken (refreshToken) {
    const url = apiBaseUrl + `/auth/token/refresh`
    return axios.post(url, {'refresh': refreshToken})
  }

  getConnectedUser (token) {
    const url = apiBaseUrl + '/connected-user'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios.get(url, {'headers': headers})
  }

  resetPassword (email) {
    const url = apiBaseUrl + '/password-reset'
    var headers = {
      'Content-Type': 'application/json'
    }
    return axios.post(
      url,
      {'email': email},
      headers
    )
  }

  confirmResetPassword (token, password) {
    const url = apiBaseUrl + '/password-reset/confirm'
    var headers = {
      'Content-Type': 'application/json'
    }
    return axios.post(
      url,
      {
        'token': token,
        'password': password
      },
      headers
    )
  }
}

import axios from 'axios'
import { apiBaseUrl } from '@/variables/localVariables'
import store from '@/store/store'
import router from '@/router'

const axios_instance = axios.create({
  baseURL:apiBaseUrl,
});

var isRefreshing = false

//Add a response interceptor
axios_instance.interceptors.response.use(
  function (response) {
    store.dispatch('AUTH_INSPECT')
      .catch(() => {
        store.commit('AUTH_LOGOUT')
        router.push('/pages/login')
      })
    return response
  },
  function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && originalRequest.url === apiBaseUrl +'/auth/token/refresh') {
     router.push('/pages/login');
     return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      if (!isRefreshing) {
        isRefreshing = true
        originalRequest._retry = true
        const refreshToken = JSON.parse(store.state.auth.token).refresh

        return axios_instance.post(apiBaseUrl +'/auth/token/refresh', {"refresh": refreshToken})
         .then(res => {
           if (res.status === 200) {
             store.commit('AUTH_REFRESH', res.data)
             originalRequest.headers['Authorization'] = 'Bearer ' + res.data.access
             return axios_instance(originalRequest)
           }
           else {
             store.commit('AUTH_LOGOUT')
             router.push('/pages/login')
             return Promise.reject(error)
           }
         })
         .catch(() => {
           store.commit('AUTH_LOGOUT')
           router.push('/pages/login')
           return Promise.reject(error)
         })
         .finally(() => {
           isRefreshing = false
         })
     }
     originalRequest.headers['Authorization'] = 'Bearer ' + JSON.parse(store.state.auth.token).access
     return axios_instance(originalRequest)
   }
   return Promise.reject(error)
});

export class APIUserConnected {

  // ----------- GLOBAL --------------
  getAuthHeaders (token) {
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }
    return headers
  }


  resetPassword (token, old_password, new_password, new_password_confirmed) {
    const url = apiBaseUrl + `/user-reset-password`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }
    var data = {
      'old_password': old_password,
      'new_password': new_password,
      'new_password_confirmed': new_password_confirmed,
    }

    return axios_instance.put(
      url, data,
      {
        'headers': headers,
      }
    )
  }

  getConnectedCollaborateur(token) {
    const url = apiBaseUrl + '/connected-collaborateur'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  updateCollaborateurProfil (token, sex, first_name, last_name,
    birthdate, phone_country_code, phone_number) {
    const url = apiBaseUrl + '/connected-collaborateur'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }
    var data = {
      'sex': sex,
      'first_name': first_name,
      'last_name': last_name,
      'birthdate': birthdate,
      'phone_country_code': phone_country_code,
      'phone_number': phone_number,
    }

    return axios_instance.put(
      url, data,
      {
        'headers': headers,
      }
    )
  }

  getCompany(token) {
    const url = apiBaseUrl + '/collaborateur-get-company'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  // --------------------- COLLABORATEURS --------------
  getAllCollaborateurs (token) {
    const url = apiBaseUrl + '/collaborateurs'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers})
  }

  searchCollaborateursByFirstNameOrLastName (token, search) {
    const url = apiBaseUrl + `/search-collaborateurs/` + search
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers})
  }

  searchCollaborateursByFirstNameOrLastNameEndPoint (search) {
    return apiBaseUrl + `/search-collaborateurs/` + search
  }

  // --------------------- CLIENTS ---------------------
  addClient (token, is_firm, contact_pk, siren, forme_sociale, representant_legal, name,
    first_name, last_name, email,
    phone_country_code, phone_number, is_domiciliation, address,
    is_different_payeur, is_payeur_firm, payeur_siren, payeur_representant_legal,
    payeur_name, payeur_address) {
    const url = apiBaseUrl + '/create-client'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var data = {
      'is_firm': is_firm,
      'siren': siren,
      'forme_sociale': forme_sociale,
      'representant_legal': representant_legal,
      'name': name,
      'first_name': first_name,
      'last_name': last_name,
      'email': email,
      'is_domiciliation': is_domiciliation,
      'address': address,
      'is_different_payeur': is_different_payeur,
      'is_payeur_firm': is_payeur_firm,
      'payeur_siren': payeur_siren,
      'payeur_representant_legal': payeur_representant_legal,
      'payeur_name': payeur_name,
      'payeur_address': payeur_address
    }
    if (contact_pk && contact_pk != '') {
      data['contact'] = contact_pk
    }
    if (phone_number) {
      data['phone_country_code'] = phone_country_code
      data['phone_number'] = phone_number
    }
    else {
      data['phone_country_code'] = ''
      data['phone_number'] = ''
    }
    return axios_instance.post(
      url,
      data,
      {
        'headers': headers,
      }
    )
  }

  modifyClient (token, client_pk, is_firm, siren, forme_sociale, representant_legal, name, first_name,
    last_name, email, phone_country_code, phone_number,
    is_domiciliation, address, is_different_payeur, is_payeur_firm, payeur_siren, payeur_representant_legal,
    payeur_name, payeur_address) {
    const url = apiBaseUrl + `/client/` + client_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var data = {
      'is_firm': is_firm,
      'siren': siren,
      'forme_sociale': forme_sociale, 
      'representant_legal': representant_legal,
      'name': name,
      'first_name': first_name,
      'last_name': last_name,
      'email': email,
      'is_domiciliation': is_domiciliation,
      'address': address,
      'is_different_payeur': is_different_payeur,
      'is_payeur_firm': is_payeur_firm,
      'payeur_siren': payeur_siren,
      'payeur_representant_legal': payeur_representant_legal,
      'payeur_name': payeur_name,
      'payeur_address': payeur_address
    }
    if (phone_number) {
      data['phone_country_code'] = phone_country_code
      data['phone_number'] = phone_number
    }
    else {
      data['phone_country_code'] = ''
      data['phone_number'] = ''
    }
    return axios_instance.put(
      url, data ,
      {
        'headers': headers,
      }
    )
  }

  archiveClient (token, client_pk) {
    const url = apiBaseUrl + `/client/` + client_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.delete(
      url,
      {
        'headers': headers,
      }
    )
  }

  activateClient (token, client_pk) {
    const url = apiBaseUrl + `/activate-client/` + client_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(
      url,
      {
        'headers': headers,
      }
    )
  }

  getAllClients (token, url) {

    /* Be careful in this page because a URL with the page is needed !!! */

    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getAllOwnClients (token, url) {

    /* Be careful in this page because a URL with the page is needed !!! */

    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  searchClientByName (token, search) {
    const url = apiBaseUrl + `/search-clients/` + search
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  searchClientBySiren (token, siren) {
    const url = apiBaseUrl + `/search-clients-by-siren/` + siren
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  searchClientByRef (token, ref) {
    const url = apiBaseUrl + `/search-clients-by-ref/` + ref
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getAllClientsByMissionType (token, url) {

    /* Be careful in this page because a URL with the page is needed !!! */

    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getClient (token, client_pk) {
    const url = apiBaseUrl + `/client/` + client_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }


  // --------------------- CONTACT ---------------------
  createNewContact (
    token, client_pk, sex, first_name, last_name,
    email, fonction, birthdate, phone_country_code, phone_number, observations) {
    const url = apiBaseUrl + '/create-contact'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }
    var data = {
      'sex': sex,
      'first_name': first_name,
      'last_name': last_name,
      'email': email,
      'fonction': fonction,
      'phone_country_code': phone_country_code,
      'phone_number': phone_number,
      'observations': observations,
    }
    if (client_pk && client_pk != '') {
      data['client'] = client_pk
    }
    if (birthdate) {
      data['birthdate'] = birthdate
    }
    else {
      data['birthdate'] = null
    }
    return axios.post(
      url,
      data,
      {
        'headers': headers,
      }
    )
  }

  getContact (token, contact_pk) {
    const url = apiBaseUrl + `/contact/` + contact_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  modifyContact (
    token, contact_pk, client_pk, sex, first_name, last_name,
    email, fonction, birthdate, phone_country_code, phone_number, observations) {
    const url = apiBaseUrl + `/contact/` + contact_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }
    var data = {
      'sex': sex,
      'first_name': first_name,
      'last_name': last_name,
      'email': email,
      'fonction': fonction,
      'phone_country_code': phone_country_code,
      'phone_number': phone_number,
      'observations': observations,
    }
    if (client_pk && client_pk != '' &&  client_pk != 'false') {
      data['client'] = client_pk
    }
    if (birthdate) {
      data['birthdate'] = birthdate
    }
    else {
      data['birthdate'] = null
    }
    return axios.put(
      url,
      data,
      {
        'headers': headers,
      }
    )
  }

  archiveContact (token, contact_pk) {
    const url = apiBaseUrl + `/contact/` + contact_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.delete(url, {'headers': headers,})
  }

  activateContact (token, contact_pk) {
    const url = apiBaseUrl + `/activate-contact/` + contact_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(url, {'headers': headers,})
  }

  unlinkContactFromClient (token, contact_pk) {
    const url = apiBaseUrl + `/unlink-contact-from-client/` + contact_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(url, {}, {'headers': headers,})
  }


  getAllContacts (token, url) {

    /* Be careful in this page because a URL with the page is needed !!! */

    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getAllOwnContacts (token, url) {

    /* Be careful in this page because a URL with the page is needed !!! */

    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getAllClientContacts (token, client_pk) {
    var url = 'all-client-contacts/' + client_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  searchContactByFirstOrLastName (token, search) {
    const url = apiBaseUrl + `/search-contacts/` + search
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  searchContactNotLinkedToClientByFirstOrLastName (token, search) {
    const url = apiBaseUrl + `/search-contacts-not-client-linked/` + search
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  searchContactByFirstOrLastNameEndPoint (search) {
    return apiBaseUrl + `/search-contacts/` + search
  }

  searchContactByLastName (token, search) {
    const url = apiBaseUrl + `/search-contacts-by-last-name/` + search
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  searchContactByEmail (token, search) {
    const url = apiBaseUrl + `/search-contacts-by-email/` + search
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }


  // ---------------- Mission Type -----------------------
  getAllMissionTypes(token) {
    const url = apiBaseUrl + '/get-all-mission-types'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }


  // ---------------- Mission ----------------------------
  connectedCollaborateurAllMissions(token) {
    const url = apiBaseUrl + '/connected-collaborateur-all-missions'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }
  getClientAllMissions(token, client_pk) {
    const url = apiBaseUrl + `/get-client-all-missions/` + client_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }
  getMission(token, pk) {
    const url = apiBaseUrl + `/get-mission/` + pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  setMissionPaiementAlert(token, pk) {
    const url = apiBaseUrl + `/set-mission-paiement-alert/` + pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(url, {'headers': headers,})
  }

  removeMissionPaiementAlert(token, pk) {
    const url = apiBaseUrl + `/remove-mission-paiement-alert/` + pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(url, {'headers': headers,})
  }

  searchMissionByContreClientSiren (token, siren) {
    const url = apiBaseUrl + `/search-missions-by-contre-client-siren/` + siren
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  searchMissionByContreClientName (token, name) {
    const url = apiBaseUrl + `/search-missions-by-contre-client-name/` + name
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }


  // ---------------- Priorité ----------------------------
  getCollaborateurAllPriorities (token) {
    const url = apiBaseUrl + '/get-collaborateur-all-priorities'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getCollaborateurMissionPriorities (token, mission_pk) {
    const url = apiBaseUrl + `/get-collaborateur-priorities/` + mission_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getCollaborateurMissionInactivePriorities (token, mission_pk) {
    const url = apiBaseUrl + `/get-collaborateur-inactive-priorities/` + mission_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  addPriority (token, mission_pk, referent_pk, name, priority_state,
    internal_ending_date, ending_date, description) {
    const url = apiBaseUrl + 'create-priority'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.post(
      url,
      {
        'mission': mission_pk,
        'referent': referent_pk,
        'name': name,
        'priority_state': priority_state,
        'internal_ending_date': internal_ending_date,
        'ending_date': ending_date,
        'description': description,
      },
      {
        'headers': headers,
      }
    )
  }

  getPriority(token, pk) {
    const url = apiBaseUrl + `/priority/` + pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  modifyPriority(token, priority_pk, referent_pk, name, priority_state,
    internal_ending_date, ending_date, description) {
    const url = apiBaseUrl + `/priority/` + priority_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(
      url,
      {
        'referent': referent_pk,
        'name': name,
        'priority_state': priority_state,
        'internal_ending_date': internal_ending_date,
        'ending_date': ending_date,
        'description': description,
      },
      {
        'headers': headers,
      }
    )
  }
  archivePriority(token, priority_pk) {
    const url = apiBaseUrl + `/priority/` + priority_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.delete(
      url,
      {
        'headers': headers,
      }
    )
  }

  activatePriority(token, priority_pk) {
    const url = apiBaseUrl + `/activate-priority/` + priority_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(
      url,
      {
        'headers': headers,
      }
    )
  }

  addCollaborateurToPriority (token, priority_pk, collaborateur_pk) {
    const url = apiBaseUrl + `/add-collaborateur-to-priority/` + priority_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(
      url,
      {
        'collaborateur_pk': collaborateur_pk,
      },
      {
        'headers': headers,
      }
    )
  }

  removeCollaborateurFromPriority (token, priority_pk, collaborateur_pk) {
    const url = apiBaseUrl + `/remove-collaborateur-from-priority/` + priority_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(
      url,
      {
        'collaborateur_pk': collaborateur_pk,
      },
      {
        'headers': headers,
      }
    )
  }

  // --------------------- PAIEMENT -------------------
  /*



  getPaiement(token, pk) {
    const url = apiBaseUrl + `/paiement/` + pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }


  */


  // ---------------- TASKS ----------------------------
  getCollaborateurTasks (token, date) {
    const url = apiBaseUrl + `/get-collaborateur-tasks/` + date
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getCollaborateurLastWeekTasks (token, date) {
    const url = apiBaseUrl + `/get-collaborateur-last-week-tasks/` + date
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }


  addTask (token, mission_pk, date, hours, minutes, description) {
    const url = apiBaseUrl + '/create-task'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var data = {
      'mission': mission_pk,
      'date': date,
      'description': description,
    }

    if (hours == '') {
      data['hours'] = 0
    }
    else {
      data['hours'] = hours
    }

    if (minutes == '') {
      data['minutes'] = 0
    }
    else {
      data['minutes'] = minutes
    }

    return axios_instance.post(
      url,
      data,
      {
        'headers': headers,
      }
    )
  }

  modifyTask(token, task_pk, mission_pk, date, hours, minutes, description) {
    const url = apiBaseUrl + `/task/` + task_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(
      url,
      {
        'mission': mission_pk,
        'date': date,
        'hours': hours,
        'minutes': minutes,
        'description': description,
      },
      {
        'headers': headers,
      }
    )
  }
  deleteTask(token, task_pk) {
    const url = apiBaseUrl + `/task/` + task_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.delete(
      url,
      {
        'headers': headers,
      }
    )
  }



  // ---------------- FRAIS ----------------------------
  getMissionFrais (token, date) {
    const url = apiBaseUrl + `/get-mission-frais/` + date
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }



  addFrais (token, mission_pk, date, montant, description, file) {
    const url = apiBaseUrl + '/create-frais'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'multipart/form-data'
    }

    var formData = new FormData()
    formData.append('mission', mission_pk);
    formData.append('date', date);
    formData.append('montant', montant);
    formData.append('description', description);
    if (file) {
      formData.append('file', file);
    }

    return axios_instance.post(
      url, formData,
      {
        'headers': headers,
      }
    )
  }

  modifyFrais(token, frais_pk, mission_pk, date, montant, description, file) {
    const url = apiBaseUrl + `/frais/` + frais_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'multipart/form-data'
    }

    var formData = new FormData()
    formData.append('mission', mission_pk);
    formData.append('date', date);
    formData.append('montant', montant);
    formData.append('description', description);
    if (file) {
      formData.append('file', file);
    }

    return axios_instance.put(
      url, formData,
      {
        'headers': headers,
      }
    )
  }
  deleteFrais(token, frais_pk) {
    const url = apiBaseUrl + `/frais/` + frais_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.delete(
      url,
      {
        'headers': headers,
      }
    )
  }

  downloadFraisFile (token, frais_pk) {
    //https://thewebtier.com/snippets/download-files-with-axios/
    const url = apiBaseUrl + '/download-frais-file/' + frais_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(
      url,
      {
        'headers': headers,
        'responseType': 'blob', // important
      },
    )
  }

  // ---------------- COURSES ----------------------------
  getAllCourses (token) {
    const url = apiBaseUrl + '/all-courses'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }


  addCourse (token, quantite, description) {
    const url = apiBaseUrl + '/create-course'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var data = {
      'description': description
    }
    if (quantite != '') {
      data['quantite'] = quantite
    }

    return axios_instance.post(
      url, data,
      {
        'headers': headers,
      }
    )
  }

  courseOrdered(token, course_pk) {
    const url = apiBaseUrl + `/course-ordered/` + course_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(
      url,
      {
        'headers': headers,
      }
    )
  }


  deleteCourse(token, course_pk) {
    const url = apiBaseUrl + `/course/` + course_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.delete(
      url,
      {
        'headers': headers,
      }
    )
  }


  // ------------ DOCUMENT ASKING ----------------

  getAllMissionDocumentAskings (token, mission_pk) {
    const url = apiBaseUrl + `/mission-all-document-asking/` + mission_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getAllClientDocumentAskings (token, client_pk) {
    const url = apiBaseUrl + `/client-all-document-asking/` + client_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }


  createDocumentAsking (token, mission_pk, name, description) {
    const url = apiBaseUrl + '/create-document-asking'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.post(
      url,
      {
        'mission': mission_pk,
        'name': name,
        'description': description
      },
      {
        'headers': headers,
      }
    )
  }


  getDocumentAsking (token, document_asking_pk) {
    const url = apiBaseUrl + `/document-asking/` + document_asking_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  deleteDocumentAsking (token, document_asking_pk) {
    const url = apiBaseUrl + `/document-asking/` + document_asking_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.delete(url, {'headers': headers,})
  }


  validateDocumentAsking (token, document_asking_pk) {
    const url = apiBaseUrl + `/validate-document-asking/` + document_asking_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(
      url, {},
      {
        'headers': headers,
      }
    )
  }

  refuseDocumentAsking (token, document_asking_pk, refused_message) {
    const url = apiBaseUrl + `/refuse-document-asking/` + document_asking_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(
      url,
      {
        'refused_message': refused_message
      },
      {
        'headers': headers,
      }
    )
  }

  // ----------------- DOCUMENTS ---------------------
  getAllDocuments (token, document_asking_pk) {
    const url = apiBaseUrl + `/get-all-documents/` + document_asking_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  downloadClientDocument (token, document_pk) {
    //https://thewebtier.com/snippets/download-files-with-axios/
    const url = apiBaseUrl + '/download-client-document/' + document_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(
      url,
      {
        'headers': headers,
        'responseType': 'blob', // important
      },
    )
  }



  // -------------- NOTIFICATIONS --------------------
  getTenLastNotifications (token) {
    const url = apiBaseUrl + '/ten-last-notifications'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  markAllNotificationsAsRead (token) {
    const url = apiBaseUrl + '/mark-all-notifications-as-read'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(url, {}, {'headers': headers,})
  }

  markNotificationAsClicked (token, notification_pk) {
    const url = apiBaseUrl + `/mark-notification-as-clicked/` + notification_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(url, {}, {'headers': headers,})
  }

  getAllNotifications (token, url) {

    /* Be careful in this page because a URL with the page is needed !!! */

    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }


  // --------- STATISTICS --------------

  getGlobalStatistics(token) {
    const url = apiBaseUrl + '/get-global-statistics'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  // ------- INSPECT AND VERIFY ACCOUNT ---------
  inspectAndVerifyConnectedAccount (token) {
    const url = apiBaseUrl + '/inspect-and-verify-connected-account'
      var headers = {
        'Authorization': 'Bearer '+ JSON.parse(token).access,
        'Content-Type': 'application/json',
      }
      return axios_instance.get(url, {'headers': headers,})
  }
}

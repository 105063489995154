import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import auth from './modules/auth'
Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,

  isGlobalErrorModal: false,

  // ------------ REGISTRATION --------------------
  createCompanyName: '',
  createCompanySiret: '',
  createCompanyFormeSociale: '',
  createCompanyPrecisionsReglementaires: '',
  createCompanyCapital: '',
  createCompanyAddress: '',
  createCompanyPhoneNumber: '',
  createCompanyPhoneNumberResult: {
    countryCode: "FR",
    isValid: false
  },
  createCompanyEmail: '',

  createCollaborateurEmail: '',
  createCollaborateurPassword: '',
  createCollaborateurConfirmPassword: '',
  createCollaborateurFirstName: '',
  createCollaborateurLastName: '',
  createCollaborateurSex: 'H',
  createCollaborateurBirthdate: '',
  createCollaborateurBirthdateYear: '1975',
  createCollaborateurBirthdateMonth: '01',
  createCollaborateurBirthdateDay: '01',
  createCollaborateurPhoneNumber: null,
  createCollaborateurPhoneNumberResult: {
    countryCode: "FR",
    isValid: false
  },
  
  
  // ----------- LOGIN -------------------------
  loginUsername: localStorage.getItem('avocanet-loginUsername') || '',
}

const mutations = {
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set (state, [variable, value]) {
    state[variable] = value
  },

  setLoginUsernameLocalStorage (state, value) {
    state.loginUsername = value
    localStorage.setItem('avocanet-loginUsername', value)
  },

  openGlobalErrorModal (state) {
    state.isGlobalErrorModal = true
  },
  closeGlobalErrorModal (state) {
    state.isGlobalErrorModal = false
  },
}

export default new Vuex.Store({
  modules: {
    user,
    auth,
  },
  strict: debug,
  state,
  mutations
})

import { USER_REQUEST, USER_ERROR, USER_SUCCESS, COMPANY_SUCCESS, LOGO_SUCCESS,  USER_LOGOUT, SUBSCRIPTION_COMPLETE, SUBSCRIPTION_CANCEL, CONNECTED_ACCOUNT_INSPECTED } from '../actions/user'
import { AUTH_LOGOUT } from '../actions/auth'
import { AuthAPIService }  from '@/api/APIAuth'

const apiAuth = new AuthAPIService()

const state = {
  status: '',
  userGroups: localStorage.getItem('avocat-user-group') || '',
  userFirstName: localStorage.getItem('avocat-user-first_name') || '',
  userLastName: localStorage.getItem('avocat-user-last_name') || '',
  userUsername: localStorage.getItem('avocat-user-username') || '',
  // The subscription
  companyName: localStorage.getItem('avocat-company-name') || '',
  companyLogo: localStorage.getItem('avocat-company-logo') || '',

  companyStripeCustomerId: localStorage.getItem('avocat-company-stripe-customer-id') || '',
  companyStripeSubscriptionId: localStorage.getItem('avocat-company-stripe-subscription-id') || '',
  companyStripeProductId: localStorage.getItem('avocat-company-stripe-product-id') || '',
  companyStripePriceId: localStorage.getItem('avocat-company-stripe-price-id') || '',
  companyStripeCurrentPeriodEnd: localStorage.getItem('avocat-company-stripe-current-period-end') || 0,
  companyStripeSubscriptionIsActive: localStorage.getItem('avocat-company-stripe-subscription-is-active') || false,
  companyStripeSubscriptionIsTrial: localStorage.getItem('avocat-company-stripe-subscription-is-trial') || false,
  companyStripeSubscriptionTrialEnd: localStorage.getItem('avocat-company-stripe-subscription-trial-end') || 0,

  companyStripeConnectedAccountId: localStorage.getItem('avocat-company-stripe-connected-account-id') || '',
  companyStripeConnectedAccountChargesEnabled: localStorage.getItem('avocat-company-stripe-connected-account-charges-enabled') || false,
  companyStripeConnectedAccountDetailsSubmitted: localStorage.getItem('avocat-company-stripe-connected-account-details-submitted') || false,
  companyStripeConnectedAccountPayoutsEnabled: localStorage.getItem('avocat-company-stripe-connected-account-payouts-enabled') || false,
}

const getters = {
  getUserGroups: state => state.userGroups,
  getUserFirstName: state => state.userFirstName,
  getUserLastName: state => state.userLastName,
  getUserUsername: state => state.userUsername,

  // The subscription
  getCompanyName: state => state.companyName,
  getCompanyLogo: state => state.companyLogo,

  getCompanyStripeCustomerId: state => state.companyStripeCustomerId,
  getCompanyStripeSubscriptionId: state => state.companyStripeSubscriptionId,
  getCompanyStripeProductId: state => state.companyStripeProductId,
  getCompanyStripePriceId: state => state.companyStripePriceId,
  getCompanyStripeCurrentPeriodEnd: state => state.companyStripeCurrentPeriodEnd,
  getCompanyStripeSubscriptionIsActive: state => state.companyStripeSubscriptionIsActive,

  getCompanyStripeSubscriptionIsTrial: state => state.companyStripeSubscriptionIsTrial,
  getCompanyStripeSubscriptionTrialEnd: state => state.companyStripeSubscriptionTrialEnd,

  getCompanyStripeConnectedAccountId: state => state.companyStripeConnectedAccountId,
  getCompanyStripeConnectedAccountChargesEnabled: state => state.companyStripeConnectedAccountChargesEnabled,
  getCompanyStripeConnectedAccountDetailsSubmitted: state => state.companyStripeConnectedAccountDetailsSubmitted,
  getCompanyStripeConnectedAccountPayoutsEnabled: state => state.companyStripeConnectedAccountPayoutsEnabled
}

const actions = {
  [USER_REQUEST]: ({commit, dispatch}, token) => {
    commit(USER_REQUEST)
    apiAuth.getConnectedUser(token)
      .then(resp => {
        commit(USER_SUCCESS, resp.data)
      })
      .catch(() => {
        commit(USER_ERROR)
        // if resp is unauthorized, logout, to
        dispatch(AUTH_LOGOUT)
      })
  },
  [USER_LOGOUT]: ({commit}) => {
    commit(USER_LOGOUT)
  }
}

const mutations = {
  [USER_REQUEST]: (state) => {
    state.status = 'loading'
  },
  [USER_SUCCESS]: (state, resp) => {
    state.status = 'success'
    state.userGroups = JSON.stringify(resp.groups)
    localStorage.setItem('avocat-user-group', JSON.stringify(resp.groups))
    state.userFirstName = resp.first_name
    localStorage.setItem('avocat-user-first_name', resp.first_name)
    state.userLastName = resp.last_name
    localStorage.setItem('avocat-user-last_name', resp.last_name)
    state.userUsername = resp.username
    localStorage.setItem('avocat-user-username', resp.username)

    // The company
    state.companyName = resp.company_name
    localStorage.setItem('avocat-company-name', resp.company_name)
    state.companyLogo = resp.logo_thumbnail
    localStorage.setItem('avocat-company-logo', resp.logo_thumbnail)
    // The subscription
    state.companyStripeCustomerId = resp.stripe_customer_id
    localStorage.setItem('avocat-company-stripe-customer-id', resp.stripe_customer_id)
    state.companyStripeSubscriptionId = resp.stripe_subscription_id
    localStorage.setItem('avocat-company-stripe-subscription-id', resp.stripe_subscription_id)
    state.companyStripeProductId = resp.stripe_product_id
    localStorage.setItem('avocat-company-stripe-product-id', resp.stripe_product_id)
    state.companyStripePriceId = resp.stripe_price_id
    localStorage.setItem('avocat-company-stripe-price-id', resp.stripe_price_id)
    state.companyStripeCurrentPeriodEnd = resp.stripe_subscription_current_period_end
    localStorage.setItem('avocat-company-stripe-current-period-end', resp.stripe_subscription_current_period_end)
    state.companyStripeSubscriptionIsActive = resp.stripe_subscription_is_active
    localStorage.setItem('avocat-company-stripe-subscription-is-active', resp.stripe_subscription_is_active)

    state.companyStripeSubscriptionIsTrial = resp.stripe_subscription_is_trial
    localStorage.setItem('avocat-company-stripe-subscription-is-trial', resp.stripe_subscription_is_trial)
    state.companyStripeSubscriptionTrialEnd = resp.stripe_subscription_trial_end
    localStorage.setItem('avocat-company-stripe-subscription-trial-end', resp.stripe_subscription_trial_end)

    state.companyStripeConnectedAccountId = resp.stripe_connected_account_id
    localStorage.setItem('avocat-company-stripe-connected-account-id', resp.stripe_connected_account_id)
    state.companyStripeConnectedAccountChargesEnabled = resp.stripe_connected_account_charges_enabled
    localStorage.setItem('avocat-company-stripe-connected-account-charges-enabled', resp.stripe_connected_account_charges_enabled)
    state.companyStripeConnectedAccountDetailsSubmitted = resp.stripe_connected_account_details_submitted
    localStorage.setItem('avocat-company-stripe-connected-account-details-submitted', resp.stripe_connected_account_details_submitted)
    state.companyStripeConnectedAccountPayoutsEnabled = resp.stripe_connected_account_payouts_enabled
    localStorage.setItem('avocat-company-stripe-connected-account-payouts-enabled', resp.stripe_connected_account_payouts_enabled)

    //Vue.set(state, 'profile', resp)
  },


  [COMPANY_SUCCESS]: (state, resp) => {
    // The company
    state.companyName = resp.company_name
    localStorage.setItem('avocat-company-name', resp.company_name)
    state.companyLogo = resp.logo_thumbnail
    localStorage.setItem('avocat-company-logo', resp.logo_thumbnail)

    // The subscription
    state.companyStripeCustomerId = resp.stripe_customer_id
    localStorage.setItem('avocat-company-stripe-customer-id', resp.stripe_customer_id)
    state.companyStripeSubscriptionId = resp.stripe_subscription_id
    localStorage.setItem('avocat-company-stripe-subscription-id', resp.stripe_subscription_id)
    state.companyStripeProductId = resp.stripe_product_id
    localStorage.setItem('avocat-company-stripe-product-id', resp.stripe_product_id)
    state.companyStripePriceId = resp.stripe_price_id
    localStorage.setItem('avocat-company-stripe-price-id', resp.stripe_price_id)
    state.companyStripeCurrentPeriodEnd = resp.stripe_subscription_current_period_end
    localStorage.setItem('avocat-company-stripe-current-period-end', resp.stripe_subscription_current_period_end)
    state.companyStripeSubscriptionIsActive = resp.stripe_subscription_is_active
    localStorage.setItem('avocat-company-stripe-subscription-is-active', resp.stripe_subscription_is_active)

    state.companyStripeSubscriptionIsTrial = resp.stripe_subscription_is_trial
    localStorage.setItem('avocat-company-stripe-subscription-is-trial', resp.stripe_subscription_is_trial)
    state.companyStripeSubscriptionTrialEnd = resp.stripe_subscription_trial_end
    localStorage.setItem('avocat-company-stripe-subscription-trial-end', resp.stripe_subscription_trial_end)

    //Vue.set(state, 'profile', resp)
  },

  [LOGO_SUCCESS]: (state, resp) => {
    state.companyLogo = resp.logo_thumbnail
    localStorage.setItem('avocat-company-logo', resp.logo_thumbnail)
  },

  [USER_ERROR]: (state) => {
    state.status = 'error'
  },
  [AUTH_LOGOUT]: (state) => {
    state.profile = {}
  },
  [USER_LOGOUT]: (state) => {
    state.userGroups = ''
    localStorage.removeItem('avocat-user-group')
    state.userFirstName = ''
    localStorage.removeItem('avocat-user-first_name')
    state.userLastName = ''
    localStorage.removeItem('avocat-user-last_name')
    state.userUsername = ''
    localStorage.removeItem('avocat-user-username')

    state.companyName = ''
    localStorage.removeItem('avocat-company-name')
    state.companyLogo = ''
    localStorage.removeItem('avocat-company-logo')

    state.companyStripeCustomerId = ''
    localStorage.removeItem('avocat-company-stripe-customer-id')
    state.companyStripeSubscriptionId = ''
    localStorage.removeItem('avocat-company-stripe-subscription-id')
    state.companyStripeProductId = ''
    localStorage.removeItem('avocat-company-stripe-product-id')
    state.companyStripePriceId = ''
    localStorage.removeItem('avocat-company-stripe-price-id')
    state.companyStripeCurrentPeriodEnd = 0
    localStorage.removeItem('avocat-company-stripe-current-period-end')
    state.companyStripeSubscriptionIsActive = ''
    localStorage.removeItem('avocat-company-stripe-subscription-is-active')
    state.companyStripeSubscriptionIsTrial = ''
    localStorage.removeItem('avocat-company-stripe-subscription-is-trial')
    state.companyStripeSubscriptionTrialEnd = ''
    localStorage.removeItem('avocat-company-stripe-subscription-trial-end')

    state.companyStripeConnectedAccountId = ''
    localStorage.removeItem('avocat-company-stripe-connected-account-id')
    state.companyStripeConnectedAccountChargesEnabled = ''
    localStorage.removeItem('avocat-company-stripe-connected-account-charges-enabled')
    state.companyStripeConnectedAccountDetailsSubmitted = ''
    localStorage.removeItem('avocat-company-stripe-connected-account-details-submitted')
    state.companyStripeConnectedAccountPayoutsEnabled = ''
    localStorage.removeItem('avocat-company-stripe-connected-account-payouts-enabled')
  },

  // ON COMPLETE SUBSCRIPTION
  [SUBSCRIPTION_COMPLETE]: (state, resp) => {
    state.companyStripeSubscriptionId = resp.stripe_subscription_id
    localStorage.setItem('avocat-company-stripe-subscription-id', resp.stripe_subscription_id)
    state.companyStripeProductId = resp.stripe_product_id
    localStorage.setItem('avocat-company-stripe-product-id', resp.stripe_product_id)
    state.companyStripePriceId = resp.stripe_price_id
    localStorage.setItem('avocat-company-stripe-price-id', resp.stripe_price_id)
    state.companyStripeCurrentPeriodEnd = resp.stripe_subscription_current_period_end
    localStorage.setItem('avocat-company-stripe-current-period-end', resp.stripe_subscription_current_period_end)
    state.companyStripeSubscriptionIsActive = resp.stripe_subscription_is_active
    localStorage.setItem('avocat-company-stripe-subscription-is-active', resp.stripe_subscription_is_active)

    state.companyStripeSubscriptionIsTrial = resp.stripe_subscription_is_trial
    localStorage.setItem('avocat-company-stripe-subscription-is-trial', resp.stripe_subscription_is_trial)
    state.companyStripeSubscriptionTrialEnd = resp.stripe_subscription_trial_end
    localStorage.setItem('avocat-company-stripe-subscription-trial-end', resp.stripe_subscription_trial_end)
  },

  [SUBSCRIPTION_CANCEL]: (state) => {
    state.companyStripeSubscriptionIsActive = false
    localStorage.setItem('avocat-company-stripe-subscription-is-active', false)
    const now = Date.now() / 1000
    state.companyStripeCurrentPeriodEnd = now - 1
    localStorage.setItem('avocat-company-stripe-current-period-end', now - 1)

    state.companyStripeSubscriptionIsTrial = false
    localStorage.setItem('avocat-company-stripe-subscription-is-trial', false)
    state.companyStripeSubscriptionTrialEnd = now - 1
    localStorage.setItem('avocat-company-stripe-subscription-trial-end', now - 1)
  },


  [CONNECTED_ACCOUNT_INSPECTED]: (state, resp) => {
    state.companyStripeConnectedAccountId = resp.id
    localStorage.setItem('avocat-company-stripe-connected-account-id', resp.id)
    state.companyStripeConnectedAccountChargesEnabled = resp.charges_enabled
    localStorage.setItem('avocat-company-stripe-connected-account-charges-enabled', resp.charges_enabled)
    state.companyStripeConnectedAccountDetailsSubmitted = resp.details_submitted
    localStorage.setItem('avocat-company-stripe-connected-account-details-submitted', resp.details_submitted)
    state.companyStripeConnectedAccountPayoutsEnabled = resp.payouts_enabled
    localStorage.setItem('avocat-company-stripe-connected-account-payouts-enabled', resp.payouts_enabled)
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import Vuex from 'vuex'
import CoreuiVue from '@coreui/vue'

import {
  FormPlugin, FormGroupPlugin, FormDatepickerPlugin, FormFilePlugin
} from 'bootstrap-vue'


import VueDayjs from 'vue-dayjs-plugin'
import 'dayjs/locale/fr'
import { iconsSet as icons } from './assets/icons/icons.js'


import browserDetect from "vue-browser-detect-plugin";

import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

import Multiselect from 'vue-multiselect'
import "vue-multiselect/dist/vue-multiselect.min.css"

import JsonExcel from "vue-json-excel";

import VueMask from 'v-mask'

import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: { id: "UA-161958571-9" }
}, router);


Vue.config.productionTip = false
Vue.use(CoreuiVue)
Vue.use(VueDayjs)
Vue.use(Vuex)
Vue.use(browserDetect)

Vue.use(FormPlugin)
Vue.use(FormGroupPlugin)
Vue.use(FormDatepickerPlugin)
Vue.use(FormFilePlugin)

Vue.component('vue-phone-number-input', VuePhoneNumberInput)

Vue.component('vue-multiselect-input', Multiselect)

//Vue.component('horizontal-stepper', HorizontalStepper)

Vue.component("downloadExcel", JsonExcel);

Vue.use(VueMask)

new Vue({
  router,
  store,
  icons,
  render: h => h(App)
}).$mount('#app')

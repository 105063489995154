import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/store'
import { APIUserConnected } from '@/api/APIUserConnected'

// Containers
const TheContainer = () => import('@/containers/TheContainer')
const SubscriptionContainer = () => import('@/containers/SubscriptionContainer')

// Views Global
const CollaborateurDashboard = () => import('@/views/CollaborateurDashboard')
const ClientDashboard = () => import('@/views/ClientDashboard')


// Views Collaborateurs
const AddCollaborateur = () => import('@/views/collaborateurs/AddCollaborateur')
const ManageCollaborateurs = () => import('@/views/collaborateurs/ManageCollaborateurs')
const CollaborateurDetails = () => import('@/views/collaborateurs/CollaborateurDetails')

// Views Settings
const ModifyPassword = () => import('@/views/settings/ModifyPassword')
const ProfileEdition = () => import('@/views/settings/ProfileEdition')
const ClientProfileEdition = () => import('@/views/settings/ClientProfileEdition')

// Views Clients
const AddClient = () => import('@/views/clients/AddClient')
const ManageClients = () => import('@/views/clients/ManageClients')
const ClientDetails = () => import('@/views/clients/ClientDetails')
const AllInactiveClients = () => import('@/views/clients/AllInactiveClients')

const CreateClientAccess = () => import('@/views/clients/CreateClientAccess')
const ClientAccessDetail = () => import('@/views/clients/ClientAccessDetail')
const ModifyClientAccessPassword = () => import('@/views/clients/ModifyClientAccessPassword')

const AccessVisualizationClientDashboard = () => import('@/views/clients/access_visualization/AccessVisualizationClientDashboard')
const AccessVisualizationClientMissionProfile = () => import('@/views/clients/access_visualization/AccessVisualizationClientMissionProfile')
const AccessVisualizationClientDocumentAskingProfile = () => import('@/views/clients/access_visualization/AccessVisualizationClientDocumentAskingProfile')
const AccessVisualizationClientFactureProfile = () => import('@/views/clients/access_visualization/AccessVisualizationClientFactureProfile')

// Views Contacts
const AddContact = () => import('@/views/contacts/AddContact')
const ManageContacts = () => import('@/views/contacts/ManageContacts')
const ContactDetails = () => import('@/views/contacts/ContactDetails')
const AllInactiveContacts = () => import('@/views/contacts/AllInactiveContacts')

// Views Mission Types
const ManageMissionTypes = () => import('@/views/mission_type/ManageMissionTypes')

// Views Missions
const AddMission = () => import('@/views/missions/AddMission')
const ManagerAllMissions = () => import('@/views/missions/ManagerAllMissions')
const MissionProfile = () => import('@/views/missions/MissionProfile')
const ModifyOrArchiveMission = () => import('@/views/missions/ModifyOrArchiveMission')
const AllInactiveMissions = () => import('@/views/missions/AllInactiveMissions')

const CollaborateurMissions = () => import('@/views/missions/CollaborateurMissions')

const ClientMissionProfile = () => import('@/views/missions/ClientMissionProfile')

// Views Priority
const AddPriority = () => import('@/views/priorities/AddPriority')
const ModifyOrArchivePriority = () => import('@/views/priorities/ModifyOrArchivePriority')

// Views Conventions
const ConventionHonoraires =  () => import('@/views/conventions/ConventionHonoraires')

// Views Devis
const AddDevis = () => import('@/views/devis/AddDevis')
const ManagerAllDevis = () => import('@/views/devis/ManagerAllDevis')
const ModifyOrArchiveDevis = () => import('@/views/devis/ModifyOrArchiveDevis')
const TurnDevisIntoFacture = () => import('@/views/devis/TurnDevisIntoFacture')

// Views Factures
const AddFacture = () => import('@/views/factures/AddFacture')
const ManagerAllFactures = () => import('@/views/factures/ManagerAllFactures')
const ModifyOrArchiveFacture = () => import('@/views/factures/ModifyOrArchiveFacture')
const AllInactiveFactures = () => import('@/views/factures/AllInactiveFactures')

const ClientFactureProfile = () => import('@/views/factures/ClientFactureProfile')

// Views Tasks
const CollaborateurTasks = () => import('@/views/tasks/CollaborateurTasks')
const ManagerTasksDashboard = () => import('@/views/tasks/ManagerTasksDashboard')

// Views Courses
const ManageCourses = () => import('@/views/courses/ManageCourses')

// Views Documents
const ClientDocumentAskingProfile = () => import('@/views/documents/ClientDocumentAskingProfile')
const CollaborateurDocumentAskingProfile = () => import('@/views/documents/CollaborateurDocumentAskingProfile')

// Views Company
const CompanyDetails = () => import('@/views/company/CompanyDetails')


// Views Default Conventions
const DefaultB2CConventionHonoraires =  () => import('@/views/conventions/DefaultB2CConventionHonoraires')
const DefaultB2BConventionHonoraires =  () => import('@/views/conventions/DefaultB2BConventionHonoraires')

// Views Notifications
const ManageNotifications = () => import('@/views/notifications/ManageNotifications')

// Views - Pages
const Login = () => import('@/views/pages/Login')
const Page404 = () => import('@/views/pages/Page404')
const PasswordReset = () => import('@/views/pages/PasswordReset')
const PasswordResetConfirm = () => import('@/views/pages/PasswordResetConfirm')

// Views - Registration
const Registration = () => import('@/views/registration/Registration')

// Views - Subscription
const CreateSubscription = () => import('@/views/subscriptions/CreateSubscription')
const ValidatedSubscription = () => import('@/views/subscriptions/ValidatedSubscription')
const ValidatedFreeTrialSubscription = () => import('@/views/subscriptions/ValidatedFreeTrialSubscription')

const UpdateSubscription = () => import('@/views/subscriptions/UpdateSubscription')

const CancelSubscription = () => import('@/views/subscriptions/CancelSubscription')
const CanceledSubscription = () => import('@/views/subscriptions/CanceledSubscription')

const ManagePaymentMethod = () => import('@/views/subscriptions/ManagePaymentMethod')

const UserInactiveSubscription = () => import('@/views/subscriptions/UserInactiveSubscription')
const BossInactiveSubscription = () => import('@/views/subscriptions/BossInactiveSubscription')

const UserExpiredFreeTrial = () => import('@/views/subscriptions/UserExpiredFreeTrial')
const BossExpiredFreeTrial = () => import('@/views/subscriptions/BossExpiredFreeTrial')

// Views - Connected account
const aidePresentationGenerale = () => import('@/views/aide/aidePresentationGenerale')
const aideRole = () => import('@/views/aide/aideRole')

// Views - Paiement
const ManagerAllPaiements = () => import('@/views/paiements/ManagerAllPaiements')

// Views - Online Paiement
const ManagerAllOnlinePaiements = () => import('@/views/online_paiements/ManagerAllOnlinePaiements')

// Pages - Online Paiement
const OnlinePaiement = () => import('@/views/pages/paiement/OnlinePaiement')

// Views - Invoice
const ManageInvoices = () => import('@/views/subscriptions/ManageInvoices')

// Views - Aide
const ConnectedAccountRefreshPage = () => import('@/views/accounts/ConnectedAccountRefreshPage')
const ConnectedAccountReturnedPage = () => import('@/views/accounts/ConnectedAccountReturnedPage')

Vue.use(Router)

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  next('/')
}

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next()
    return
  }
  next('/pages/login')
}

const isUserGroup = (group_name) => {
  var userGroups = store.getters.getUserGroups
  var userGroupsArray = []
  var is_contained = false
  if (userGroups) {
    try {
      var userGroupsJson = JSON.parse(userGroups)
      for (var i = 0; i < userGroupsJson.length; i++) {
        userGroupsArray.push(
          userGroupsJson[i]['name']
        )
      }
    }
    catch(error) {
      return is_contained
    }
  }

  for (var j = 0; j < group_name.length; j++) {
    if (userGroupsArray.indexOf(group_name[j]) > -1) {
      is_contained = true
    }
  }
  return is_contained
}

const isConnectedCollaborateur = (to, from, next ) => {

  // Check if user is authenticated
  if (store.getters.isAuthenticated) {

    // Check if the subscription is ok
    const now = parseInt(Date.now() / 1000)
    const stripe_subscription_id = store.getters.getCompanyStripeSubscriptionId
    const stripe_subscription_current_period_end = store.getters.getCompanyStripeCurrentPeriodEnd
    const stripe_subscription_is_active = store.getters.getCompanyStripeSubscriptionIsActive

    const stripe_subscription_is_trial = store.getters.getCompanyStripeSubscriptionIsTrial
    const stripe_subscription_trial_end = store.getters.getCompanyStripeSubscriptionTrialEnd

    if (stripe_subscription_id === '') {
      store.dispatch('AUTH_LOGOUT').then(() => {
        next('/pages/login')
      })
    }

    // FOR THE TRIAL : CHECK IF TRIAL IS ACTIF
    else if ((stripe_subscription_is_trial == 'true' || stripe_subscription_is_trial == true) && parseFloat(stripe_subscription_trial_end) >= now) {
      next()
    }

    else if ((stripe_subscription_is_active == 'false' || stripe_subscription_is_active == false) || parseFloat(stripe_subscription_current_period_end) < now)  {
      const apiUserConnected = new APIUserConnected()
      apiUserConnected.getCompany(store.state.auth.token)
        .then((result) => {
          store.commit('COMPANY_SUCCESS', result.data)

          if (result.data.stripe_subscription_id === '') {
            store.dispatch('AUTH_LOGOUT').then(() => {
              next('/pages/login')
            })
          }
          else if (result.data.stripe_subscription_is_active == false || result.data.stripe_subscription_current_period_end < now)  {
            store.dispatch('AUTH_LOGOUT').then(() => {
              next('/pages/login')
            })
          }
          else {
            // Check if user in good group
            if (isUserGroup(['COLLAB'])) {
              next()
              return
            }
            else {
              next('/')
            }
          }
        })
        .catch((e) => {
          console.log(e)
          /*store.dispatch('AUTH_LOGOUT').then(() => {
            next('/pages/login')
          })*/
        })

    }
    else {
      // Check if user in good group
      if (isUserGroup(['COLLAB'])) {
        next()
        return
      }
      else {
        next('/')
      }
    }
  }
  else {
    next('/pages/login')
  }
}



const isConnectedManager = (to, from, next ) => {

  // Check if user is authenticated
  if (store.getters.isAuthenticated) {

    // Check if the subscription is ok
    const now = parseInt(Date.now() / 1000)
    const stripe_subscription_id = store.getters.getCompanyStripeSubscriptionId
    const stripe_subscription_current_period_end = store.getters.getCompanyStripeCurrentPeriodEnd
    const stripe_subscription_is_active = store.getters.getCompanyStripeSubscriptionIsActive

    const stripe_subscription_is_trial = store.getters.getCompanyStripeSubscriptionIsTrial
    const stripe_subscription_trial_end = store.getters.getCompanyStripeSubscriptionTrialEnd

    if (stripe_subscription_id === '') {
      store.dispatch('AUTH_LOGOUT').then(() => {
        next('/pages/login')
      })
    }

    // FOR THE TRIAL : CHECK IF TRIAL IS ACTIF
    else if ((stripe_subscription_is_trial == 'true' || stripe_subscription_is_trial == true) && parseFloat(stripe_subscription_trial_end) >= now) {
      next()
    }


    else if ((stripe_subscription_is_active == 'false' || stripe_subscription_is_active == false) || parseFloat(stripe_subscription_current_period_end) < now)  {
      const apiUserConnected = new APIUserConnected()
      apiUserConnected.getCompany(store.state.auth.token)
        .then((result) => {
          store.commit('COMPANY_SUCCESS', result.data)

          if (result.data.stripe_subscription_id === '') {
            store.dispatch('AUTH_LOGOUT').then(() => {
              next('/pages/login')
            })
          }
          else if (result.data.stripe_subscription_is_active == false || result.data.stripe_subscription_current_period_end < now)  {
            store.dispatch('AUTH_LOGOUT').then(() => {
              next('/pages/login')
            })
          }
          else {
            // Check if user in good group
            if (isUserGroup(['MANAGER'])) {
              next()
              return
            }
            else {
              next('/')
            }
          }
        })
        .catch((e) => {
          console.log(e)
          /*store.dispatch('AUTH_LOGOUT').then(() => {
            next('/pages/login')
          })*/
        })

    }
    else {
      // Check if user in good group
      if (isUserGroup(['MANAGER'])) {
        next()
        return
      }
      else {
        next('/')
      }
    }
  }
  else {
    next('/pages/login')
  }
}



const isConnectedAdmin = (to, from, next ) => {

  // Check if user is authenticated
  if (store.getters.isAuthenticated) {

    // Check if the subscription is ok
    const now = parseInt(Date.now() / 1000)
    const stripe_subscription_id = store.getters.getCompanyStripeSubscriptionId
    const stripe_subscription_current_period_end = store.getters.getCompanyStripeCurrentPeriodEnd
    const stripe_subscription_is_active = store.getters.getCompanyStripeSubscriptionIsActive

    const stripe_subscription_is_trial = store.getters.getCompanyStripeSubscriptionIsTrial
    const stripe_subscription_trial_end = store.getters.getCompanyStripeSubscriptionTrialEnd

    if (stripe_subscription_id === '') {
      store.dispatch('AUTH_LOGOUT').then(() => {
        next('/pages/login')
      })
    }

    // FOR THE TRIAL : CHECK IF TRIAL IS ACTIF
    else if ((stripe_subscription_is_trial == 'true' || stripe_subscription_is_trial == true) && parseFloat(stripe_subscription_trial_end) >= now) {
      next()
    }

    else if ((stripe_subscription_is_active == 'false' || stripe_subscription_is_active == false) || parseFloat(stripe_subscription_current_period_end) < now)  {
      const apiUserConnected = new APIUserConnected()
      apiUserConnected.getCompany(store.state.auth.token)
        .then((result) => {
          store.commit('COMPANY_SUCCESS', result.data)

          if (result.data.stripe_subscription_id === '') {
            store.dispatch('AUTH_LOGOUT').then(() => {
              next('/pages/login')
            })
          }
          else if (result.data.stripe_subscription_is_active == false || result.data.stripe_subscription_current_period_end < now)  {
            store.dispatch('AUTH_LOGOUT').then(() => {
              next('/pages/login')
            })
          }
          else {
            // Check if user in good group
            if (isUserGroup(['ADMIN'])) {
              next()
              return
            }
            else {
              next('/')
            }
          }
        })
        .catch((e) => {
          console.log(e)
          /*store.dispatch('AUTH_LOGOUT').then(() => {
            next('/pages/login')
          })*/
        })

    }
    else {
      // Check if user in good group
      if (isUserGroup(['ADMIN'])) {
        next()
        return
      }
      else {
        next('/')
      }
    }
  }
  else {
    next('/pages/login')
  }
}



const isConnectedClient = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    if (isUserGroup(['CLIENT'])) {
      next()
      return
    }
    else {
      next('/')
    }
  }
  else {
    next('/pages/login')
  }
}


const isConnectedBoss = (to, from, next ) => {

  // Check if user is authenticated
  if (store.getters.isAuthenticated) {

    // Check if the subscription is ok
    const now = parseInt(Date.now() / 1000)
    const stripe_subscription_id = store.getters.getCompanyStripeSubscriptionId
    const stripe_subscription_current_period_end = store.getters.getCompanyStripeCurrentPeriodEnd
    const stripe_subscription_is_active = store.getters.getCompanyStripeSubscriptionIsActive

    const stripe_subscription_is_trial = store.getters.getCompanyStripeSubscriptionIsTrial
    const stripe_subscription_trial_end = store.getters.getCompanyStripeSubscriptionTrialEnd

    if (stripe_subscription_id === '') {
      store.dispatch('AUTH_LOGOUT').then(() => {
        next('/pages/login')
      })
    }

    // FOR THE TRIAL : CHECK IF TRIAL IS ACTIF
    else if ((stripe_subscription_is_trial == 'true' || stripe_subscription_is_trial == true) && parseFloat(stripe_subscription_trial_end) >= now) {
      next()
    }

    else if ((stripe_subscription_is_active == 'false' || stripe_subscription_is_active == false) || parseFloat(stripe_subscription_current_period_end) < now)  {
      const apiUserConnected = new APIUserConnected()
      apiUserConnected.getCompany(store.state.auth.token)
        .then((result) => {
          store.commit('COMPANY_SUCCESS', result.data)

          if (result.data.stripe_subscription_id === '') {
            store.dispatch('AUTH_LOGOUT').then(() => {
              next('/pages/login')
            })
          }
          else if (result.data.stripe_subscription_is_active == false || result.data.stripe_subscription_current_period_end < now)  {
            store.dispatch('AUTH_LOGOUT').then(() => {
              next('/pages/login')
            })
          }
          else {
            // Check if user in good group
            if (isUserGroup(['BOSS'])) {
              next()
              return
            }
            else {
              next('/')
            }
          }
        })
        .catch((e) => {
          console.log(e)
          /*store.dispatch('AUTH_LOGOUT').then(() => {
            next('/pages/login')
          })*/
        })

    }
    else {
      // Check if user in good group
      if (isUserGroup(['BOSS'])) {
        next()
        return
      }
      else {
        next('/')
      }
    }
  }
  else {
    next('/pages/login')
  }
}



const isConnectedBossWithoutSubscription = (to, from, next ) => {

  // Check if user is authenticated
  if (store.getters.isAuthenticated) {

    // Check if the subscription is ok
    const now = Date.now() / 1000
    const stripe_subscription_id = store.getters.getCompanyStripeSubscriptionId
    const stripe_subscription_current_period_end = store.getters.getCompanyStripeCurrentPeriodEnd
    const stripe_subscription_is_active = store.getters.getCompanyStripeSubscriptionIsActive

    const stripe_subscription_is_trial = store.getters.getCompanyStripeSubscriptionIsTrial
    const stripe_subscription_trial_end = store.getters.getCompanyStripeSubscriptionTrialEnd

    if (stripe_subscription_id === '') {
      next()
    }

    // FOR THE TRIAL : CHECK IF TRIAL IS ACTIF
    else if ((stripe_subscription_is_trial == 'true' || stripe_subscription_is_trial == true) && parseFloat(stripe_subscription_trial_end) < now) {
      next()
    }

    else if ((stripe_subscription_is_active == 'false' || stripe_subscription_is_active == false) || parseFloat(stripe_subscription_current_period_end) < now)  {
      next()
    }
    else {
      // Check if user in good group
      if (isUserGroup(['BOSS'])) {
        next('/')
        return
      }
      else {
        next('/')
      }
    }
  }
  else {
    next('/pages/login')
  }
}



export default new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: configRoutes()
})

function configRoutes () {
  return [
    {
      path: '/',
      redirect: () => {
        if (store.getters.isAuthenticated) {
          if (isUserGroup(['CLIENT'])) {
            return { path: '/client-dashboard'}
          }
          else if (isUserGroup(['COLLAB'])) {
            return { path: '/dashboard'}
          }
          else {
            return { path : '/pages/404'}
          }
        }
        else {
          return { path: '/pages/login'}
        }
      },
      name: 'Accueil',
      component: TheContainer,
      children: [
        {
          path: 'dashboard',
          name: 'Tableau de bord',
          component: CollaborateurDashboard,
          beforeEnter: isConnectedCollaborateur
        },

        {
          path: 'client-dashboard',
          name: 'Votre tableau de bord client',
          component: ClientDashboard,
          beforeEnter: isConnectedClient
        },

        // -------------- SETTINGS ----------------
        {
          path: '/settings/modify-password',
          name: 'Modifier votre mot de passe',
          component: ModifyPassword,
          beforeEnter: ifAuthenticated
        },
        {
          path: '/settings/profile-edition',
          name: 'Editer votre profil',
          component: ProfileEdition,
          beforeEnter: isConnectedCollaborateur
        },
        {
          path: '/settings/client-profile-edition',
          name: 'Client - Editer votre profil',
          component: ClientProfileEdition,
          beforeEnter: isConnectedClient
        },

        // -------------- COLLABORATEURS ----------------
        {
          path: '/administration/add-collaborateur',
          name: 'Ajouter un collaborateur',
          component: AddCollaborateur,
          beforeEnter: isConnectedAdmin
        },
        {
          path: '/administration/manage-collaborateurs',
          name: 'Vos collaborateurs',
          component: ManageCollaborateurs,
          beforeEnter: isConnectedAdmin
        },
        {
          path: '/administration/collaborateur/:id',
          name: 'Votre collaborateur',
          component: CollaborateurDetails,
          beforeEnter: isConnectedAdmin
        },

        // -------------- CLIENTS ---------------------
        {
          path: '/add-new-client',
          name: 'Ajouter un nouveau client',
          component: AddClient,
          beforeEnter: isConnectedCollaborateur
        },
        {
          path: '/manage-clients',
          name: 'Vos clients',
          component: ManageClients,
          beforeEnter: isConnectedCollaborateur
        },
        {
          path: '/client-details/:id',
          name: 'Visualiser votre client',
          component: ClientDetails,
          beforeEnter: isConnectedCollaborateur
        },
        {
          path: '/archives/clients',
          name: 'Vos clients archivés',
          component: AllInactiveClients,
          beforeEnter: isConnectedManager
        },

        {
          path: '/create-client-access/:id',
          name: 'Créer un accès client',
          component: CreateClientAccess,
          beforeEnter: isConnectedManager
        },
        {
          path: '/client-access/:id',
          name: 'Accès client',
          component: ClientAccessDetail,
          beforeEnter: isConnectedManager
        },
        {
          path: '/modify-client-password/:id',
          name: 'Accès client - Mot de passe',
          component: ModifyClientAccessPassword,
          beforeEnter: isConnectedManager
        },


        {
          path: '/visualisation/access-client/:id',
          name: 'Visualisation - Accès client',
          component: AccessVisualizationClientDashboard,
          beforeEnter: isConnectedManager
        },
        {
          path: '/visualisation/access-client/mission/:id',
          name: 'Visualisation - Accès client - Mission',
          component: AccessVisualizationClientMissionProfile,
          beforeEnter: isConnectedManager
        },
        {
          path: '/visualisation/access-client/documents/:id',
          name: 'Visualisation - Accès client - Demande de documents',
          component: AccessVisualizationClientDocumentAskingProfile,
          beforeEnter: isConnectedManager
        },
        {
          path: '/visualisation/access-client/facture/:id',
          name: 'Visualisation - Accès client - Facture',
          component: AccessVisualizationClientFactureProfile,
          beforeEnter: isConnectedManager
        },


        // -------------- CONTACTS ---------------------
        {
          path: '/add-new-contact',
          name: 'Ajouter un nouveau contact',
          component: AddContact,
          beforeEnter: isConnectedCollaborateur
        },
        {
          path: '/manage-contacts',
          name: 'Vos contacts',
          component: ManageContacts,
          beforeEnter: isConnectedCollaborateur
        },
        {
          path: '/contact-details/:id',
          name: 'Visualiser votre contact',
          component: ContactDetails,
          beforeEnter: isConnectedCollaborateur
        },
        {
          path: '/archives/contacts',
          name: 'Vos contacts archivés',
          component: AllInactiveContacts,
          beforeEnter: isConnectedManager
        },

        // ------------ MISSION TYPE ----------------------
        {
          path: '/administration/manage-mission-types',
          name: 'Vos types de mission',
          component: ManageMissionTypes,
          beforeEnter: isConnectedAdmin
        },

        // ------------ MISSION ----------------------
        {
          path: '/add-new-mission',
          name: 'Créer une nouvelle mission',
          component: AddMission,
          beforeEnter: isConnectedManager
        },
        {
          path: '/manager-all-missions',
          name: 'Toutes vos missions',
          component: ManagerAllMissions,
          beforeEnter: isConnectedManager
        },
        {
          path: '/mission/:id',
          name: 'Votre mission',
          component: MissionProfile,
          beforeEnter: isConnectedCollaborateur
        },
        {
          path: '/manager-modify-mission/:id',
          name: 'Modifier votre mission',
          component: ModifyOrArchiveMission,
          beforeEnter: isConnectedManager
        },

        {
          path: '/vos-missions',
          name: 'Vos mission',
          component: CollaborateurMissions,
          beforeEnter: isConnectedCollaborateur
        },

        {
          path: '/archives/missions',
          name: 'Vos missions archivées',
          component: AllInactiveMissions,
          beforeEnter: isConnectedManager
        },

        {
          path: '/client-mission/:id',
          name: 'Client - Votre mission',
          component: ClientMissionProfile,
          beforeEnter: isConnectedClient
        },

        // ------------ PRIORITE ----------------------
        {
          path: '/add-new-priority/:id',
          name: 'Ajouter un priorité',
          component: AddPriority,
          beforeEnter: isConnectedCollaborateur
        },
        {
          path: '/modify-priority/:mission_pk/:priority_pk',
          name: 'Modifier votre priorité',
          component: ModifyOrArchivePriority,
          beforeEnter: isConnectedCollaborateur
        },


        // ------------ CONVENTIONS HONORAIRES B2C ----------------------
        {
          path: '/convention-honoraires/:id',
          name: "Convention d'honoraires",
          component: ConventionHonoraires,
          beforeEnter: isConnectedManager
        },

        // ------------ DEVIS ----------------------
        {
          path: '/add-new-devis/:id',
          name: 'Ajouter un devis',
          component: AddDevis,
          beforeEnter: isConnectedManager
        },
        {
          path: '/manager-dashboard-devis/',
          name: 'Tableau de bord de vos devis',
          component: ManagerAllDevis,
          beforeEnter: isConnectedManager
        },
        {
          path: '/modify-devis/:devis_pk',
          name: 'Votre devis',
          component: ModifyOrArchiveDevis,
          beforeEnter: isConnectedManager
        },
        {
          path: '/create-facture-from-devis/:idDevis/:idMission/:title/:address',
          name: 'Générer une facture à partir de votre devis',
          component: TurnDevisIntoFacture,
          beforeEnter: isConnectedManager
        },

        // ------------ FACTURE ----------------------
        {
          path: '/add-new-facture/:id',
          name: 'Ajouter une facture',
          component: AddFacture,
          beforeEnter: isConnectedManager
        },
        {
          path: '/manager-dashboard-factures/',
          name: 'Tableau de bord de vos factures',
          component: ManagerAllFactures,
          beforeEnter: isConnectedManager
        },
        {
          path: '/modify-facture/:facture_pk',
          name: 'Votre facture',
          component: ModifyOrArchiveFacture,
          beforeEnter: isConnectedManager
        },
        {
          path: '/archives/factures',
          name: 'Vos factures archivées',
          component: AllInactiveFactures,
          beforeEnter: isConnectedManager
        },

        {
          path: '/client-facture/:facture_pk',
          name: 'Client - Votre factures',
          component: ClientFactureProfile,
          beforeEnter: isConnectedClient
        },

        // ------------ PAIEMENTS --------
        {
          path: '/manager-dashboard-paiements/',
          name: 'Tableau de bord de vos paiements',
          component: ManagerAllPaiements,
          beforeEnter: isConnectedManager
        },

        // ------------ PAIEMENTS EN LIGNE --------
        {
          path: '/manager-dashboard-paiements-en-ligne/',
          name: 'Tableau de bord de vos paiements en ligne',
          component: ManagerAllOnlinePaiements,
          beforeEnter: isConnectedManager
        },

        // ------------ TASK ----------------------
        {
          path: '/vos-taches/',
          name: 'Vos tâches',
          component: CollaborateurTasks,
          beforeEnter: isConnectedCollaborateur
        },
        {
          path: '/manager-dashboard-tasks/:client_pk?/:mission_pk?',
          name: 'Tableau de bord des tâches ',
          component: ManagerTasksDashboard,
          beforeEnter: isConnectedManager
        },

        // ------------ COURSES ----------------------
        {
          path: '/logistique/courses/',
          name: 'Liste de courses',
          component: ManageCourses,
          beforeEnter: isConnectedCollaborateur
        },

        // ----------- DOCUMENTS ---------------------
        {
          path: '/client-documents/:document_asking_pk',
          name: 'Demande de documents',
          component: ClientDocumentAskingProfile,
          beforeEnter: isConnectedClient
        },
        {
          path: '/mission-documents/:document_asking_pk',
          name: 'Votre demande de documents',
          component: CollaborateurDocumentAskingProfile,
          beforeEnter: isConnectedCollaborateur
        },

        // ----------- COMPANY ----------------------
        {
          path: '/company/',
          name: 'Votre entreprise',
          component: CompanyDetails,
          beforeEnter: isConnectedBoss
        },


        // ------------ DEFAULT CONVENTIONS HONORAIRES B2C ----------------------
        {
          path: '/parametres/default-convention-honoraires-b2c/',
          name: "Convention d'honoraires B2C par défaut",
          component: DefaultB2CConventionHonoraires,
          beforeEnter: isConnectedBoss
        },
        {
          path: '/parametres/default-convention-honoraires-b2b/',
          name: "Convention d'honoraires B2B par défaut",
          component: DefaultB2BConventionHonoraires,
          beforeEnter: isConnectedBoss
        },

        // ----------- NOTIFICATIONS ----------------------
        {
          path: '/notifications/',
          name: 'Vos notifications',
          component: ManageNotifications,
          beforeEnter: ifAuthenticated
        },


        // ---------- SUBSCRIPTION ------------------
        {
          path: 'abonnement/souscription/',
          name: 'Finaliser votre souscription',
          component: UpdateSubscription,
          beforeEnter: isConnectedBossWithoutSubscription
        },
        {
          path: 'abonnement/validation/',
          name: 'Votre abonnement a été validé',
          component: ValidatedSubscription,
          beforeEnter: isConnectedBoss
        },
        {
          path: 'abonnement/periode-essai-validation/',
          name: "Votre période d'essai a été validé",
          component: ValidatedFreeTrialSubscription,
          beforeEnter: isConnectedBoss
        },
        {
          path: 'abonnement/resiliation/',
          name: 'Votre abonnement - Résiliation',
          component: CancelSubscription,
          beforeEnter: isConnectedBoss
        },
        {
          path: 'abonnement/vos-moyens-de-paiement/',
          name: 'Vos moyens de paiement',
          component: ManagePaymentMethod,
          beforeEnter: isConnectedBoss
        },
        {
          path: 'abonnement/vos-factures/',
          name: 'Eclerk - Vos factures',
          component: ManageInvoices,
          beforeEnter: isConnectedBoss
        },

        // ------------ CONNECTED ACCOUNT -----------
        {
          path: 'compte/validation/',
          name: 'Compte - Fin du process',
          component: ConnectedAccountReturnedPage,
          beforeEnter: ifAuthenticated
        },
        {
          path: 'compte/erreur/',
          name: 'Compte - Erreur',
          component: ConnectedAccountRefreshPage,
          beforeEnter: ifAuthenticated
        },

        // ------------ AIDE -----------
        {
          path: 'aide/presentation/',
          name: 'Aide - Présentation',
          component: aidePresentationGenerale,
          beforeEnter: ifAuthenticated
        },
        {
          path: 'aide/role/',
          name: 'Aide - Rôles',
          component: aideRole,
          beforeEnter: ifAuthenticated
        },

      ]
    },
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: 'login',
          name: 'Login',
          component: Login,
          beforeEnter: ifNotAuthenticated
        },
        {
          path: 'inscription',
          name: 'Inscription',
          component: Registration,
          beforeEnter: ifNotAuthenticated
        },
        {
          path: 'settings/reset-password',
          name: 'PasswordReset',
          component: PasswordReset,
          beforeEnter: ifNotAuthenticated
        },
        {
          path: 'settings/reset-password-confirm',
          name: 'PasswordResetConfirm',
          component: PasswordResetConfirm,
          beforeEnter: ifNotAuthenticated
        },
        {
          path: 'abonnement/invalide',
          name: 'Abonnement annulé ou expiré',
          component: UserInactiveSubscription,
          beforeEnter: ifAuthenticated
        },
        {
          path: 'administrateur/abonnement/invalide',
          name: 'Administrateur - Abonnement annulé ou expiré',
          component: BossInactiveSubscription,
          beforeEnter: ifAuthenticated
        },
        {
          path: 'abonnement/periode-essai/expire',
          name: "Période d'essaie expirée",
          component: UserExpiredFreeTrial,
          beforeEnter: ifAuthenticated
        },
        {
          path: 'administrateur/abonnement/periode-essai/expire',
          name: "Administrateur - Période d'essaie expirée",
          component: BossExpiredFreeTrial,
          beforeEnter: ifAuthenticated
        },
        {
          path: 'abonnement/resilie',
          name: 'Abonnement résilié',
          component: CanceledSubscription,
        },

        // ------ Online paiement -----
        {
          path: 'paiement-en-ligne/:uri',
          name: 'Paiement en ligne',
          component: OnlinePaiement,
        },
      ]
    },

    {
      path: '/abonnement',
      redirect: '/pages/404',
      component: SubscriptionContainer,
      children: [
        {
          path: 'creation',
          name: 'Abonnement - Choisissez votre abonnement',
          component: CreateSubscription,
          beforeEnter: isConnectedBossWithoutSubscription
        },
        {
          path: 'votre-mot-de-passe',
          name: 'Abonnement - Modifier votre mot de passe',
          component: ModifyPassword,
          beforeEnter: isConnectedBossWithoutSubscription
        },
        {
          path: 'votre-profil',
          name: 'Abonnement - Votre profil',
          component: ProfileEdition,
          beforeEnter: isConnectedBossWithoutSubscription
        },
        {
          path: 'votre-entreprise',
          name: 'Abonnement - Votre entreprise',
          component: CompanyDetails,
          beforeEnter: isConnectedBossWithoutSubscription
        },
      ]
    },

    {
      path: '*',
      redirect: '/pages/404',
    }
  ]
}

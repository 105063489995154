<template>
  <div>
    <globalErrorModalComponent />
    <router-view></router-view>
  </div>
</template>


<script>
import globalErrorModalComponent from '@/components/globalErrorModalComponent'
export default {
  name: 'App',
  components: {
    globalErrorModalComponent
  }
}

</script>
<style>
/* OPEN SANS */
/* ITALIC */
@font-face{
  font-family : 'Open Sans';
  src: url("./assets/fonts/open-sans/OpenSans-Italic.ttf");
  font-style : italic, oblique;
  font-weight : 400;
  text-rendering : optimizeLegibility;
}
/* REGULAR */
@font-face{
  font-family: "Open Sans";
  src: url("./assets/fonts/open-sans/OpenSans-Regular.ttf");
  font-style : normal;
  font-weight : 400;
  text-rendering : optimizeLegibility;
}
/* SEMI BOLD */
@font-face{
  font-family: "Open Sans";
  src: url("./assets/fonts/open-sans/OpenSans-SemiBold.ttf");
  font-style : normal;
  font-weight : 600;
  text-rendering : optimizeLegibility;
}

/* BOLD */
@font-face{
  font-family: "Open Sans";
  src: url("./assets/fonts/open-sans/OpenSans-Bold.ttf");
  font-style : normal;
  font-weight : 700;
  text-rendering : optimizeLegibility;
}


@font-face {
  font-family: "Dosis";
  font-style: normal;
  src: url("./assets/fonts/dosis/Dosis-Medium.ttf");
  font-display: swap;
}


.font-dosis-title {
  font-family: Dosis, sans-serif !important;
  letter-spacing: 0.2em;
}

.font-dosis {
  font-family: Dosis, sans-serif !important;
  letter-spacing: 0.1em;
}

body {
  font-family: Open Sans, sans-serif !important;
  font-size: 1rem !important;
  background-color: rgb(252, 252, 255) !important;
}

.form-control {
  font-size: 1rem !important;
}
.list-group-item {
  background-color: #FFFFFF !important;
}

.list-group-item.active {
  background-color: #007BFF !important;
}

/* ------------- CUSTOM THE INSIDE OF THE APP ----------------- */

h1 {
  font-size: 1.7rem !important;
  font-weight: 700 !important;
}

h2 {
  font-size: 1.3rem !important;
  font-weight: 600 !important;
}

/* ------------- CUSTOMIZE BOOTSTRAP COLOR --------------------*/


/* ------------- THE MULTISELECT CUSTOM ----------------------*/
.multiselect__tags {
  min-height: 40px;
  display: block;
  padding: 8px 40px 0 8px;
  border-radius: 5px;
  border: 1px solid #ced4da !important;
  background: #fff;
  font-size: 14px;
}

.multiselect__tag {
    position: relative;
    display: inline-block;
    padding: 4px 26px 4px 10px;
    border-radius: 5px;
    margin-right: 10px;
    color: #fff;
    line-height: 1;
    background: #768192 !important;
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
}

.multiselect__tag-icon:after {
    color: white !important;
}

.multiselect__tag-icon:focus, .multiselect__tag-icon:hover {
    background: #4f5d73 !important;
}



</style>
<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';

  $body-bg: #0000cc;

  @import '~bootstrap/dist/css/bootstrap.min.css';
  @import '~bootstrap-vue/dist/bootstrap-vue.css';
</style>
